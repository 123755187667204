import { CTX, TRepository } from '@sennder/microfrontend-registry'
import { IOrcasSharedData } from '@sennder/senn-node-microfrontend-interfaces'
import { deepToRaw } from '@sennder/shell-utilities/dist/functions/createMfWrapper/utils'

import {
  getStateCallbacks,
  getStateData,
  getStateProviders,
} from '@/store/getters'
import { MicroFrontendLogger } from '@sennder/shell-utilities'
import { AppAnalyticsProvider } from '@/services/analyticsProvider'
import router from '@/router'
import { loggerInstance } from '@/services/logger'
import { routes } from './routes'
import { getMicrofrontendData } from '@/store'
import { watch, WatchStopHandle } from 'vue'
import { routeNavigationGuard } from '@/router/routes'
import { DEFAULT_ROUTE } from '@/common/constants'

export const getMfContext = async (
  mf: TRepository
): Promise<Readonly<CTX<IOrcasSharedData>>> => {
  const routeName = router.currentRoute.value.name
  const route = Object.values(routes).find((r) => r.name === routeName)

  const npmName = mf.meta.fml.npmName

  const logger: MicroFrontendLogger = new MicroFrontendLogger(
    {
      codeOwners: mf.meta.codeOwners,
      module: npmName,
    },
    () => loggerInstance
  )

  const analyticsProvider: AppAnalyticsProvider = new AppAnalyticsProvider(
    route?.context.analytics || {
      module: npmName,
      submodule: npmName,
    }
  )

  const context: CTX<IOrcasSharedData> = {
    data: deepToRaw(await getMicrofrontendData(npmName)),
    callbacks: {
      ...getStateCallbacks(),
      syncParentRouter: async (route: any) => {
        const currentPath = router.currentRoute.value.fullPath
        if (
          (typeof route === 'string' && currentPath === route) ||
          (typeof route === 'object' && currentPath === route.path)
        ) {
          return
        }
        await router.push(route)
      },
    },
    providers: {
      ...getStateProviders(),
      logger,
      segment: analyticsProvider,
      analytics: analyticsProvider,
    },
    hooks: [
      (_, mfInstance) => {
        /* TODO: what's the better way to discover "pages"?
          Currently a lot of widgets still return syncChildRouter for no good reason
         */
        const isPage = !!mfInstance.syncChildRouter

        const watchHandles: WatchStopHandle[] = []

        if (isPage) {
          // sync child router with parent router
          watchHandles.push(
            watch(
              () => router.currentRoute.value,
              (to) => {
                mfInstance.syncChildRouter(to.fullPath)
              },
              {
                immediate: true,
              }
            )
          )
          // re-execute navigation guard for current route when feature flags change
          watchHandles.push(
            watch(
              () => getStateData().featureFlags,
              async () => {
                const currentRoute = router.currentRoute.value
                if (!currentRoute) {
                  return
                }
                // re-execute navigation guards for current route
                const result = await routeNavigationGuard(
                  currentRoute,
                  currentRoute
                )
                if (result !== true) {
                  if (result === false) {
                    router.push(DEFAULT_ROUTE)
                  } else {
                    router.push(result)
                  }
                }
              }
            )
          )
        }

        return () => {
          if (watchHandles.length) {
            for (const unwatch of watchHandles) {
              unwatch()
            }
            watchHandles.length = 0
          }
        }
      },
    ],
  }

  return context
}
