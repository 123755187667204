import {
  identifyUserInAnalytics,
  initAnalytics,
  resetAnalyticsSession,
  stopAnalytics,
} from './analytics'
import { identifyUserInLogger, initLogger, stopLogger } from '@/services/logger'
import { logger } from '@/services/logger/loggers'
import {
  identifyUserInMonitor,
  initMonitor,
  stopMonitor,
} from '@/services/monitor'
import {
  FULLSTORY_ORGID,
  isLocalEnv,
  isProductionEnv,
  HOTJAR_PROJECT_ID,
} from '@/common/config'
import { Fullstory } from '@sennder/shell-utilities'
import { getStateData, getStateProfile } from '@/store/getters'
import { SharedDataType } from '@sennder/senn-node-microfrontend-interfaces'
import { amplitude } from '@sennder/senn-node-analytics-frontend'
import { HotjarPlugin, HotjarPluginName } from '@/plugins/HotjarPlugin'
import { watch } from 'vue'

let state: 'stopped' | 'started' = 'stopped'

/* 
  Hotjar configuration can be changed on the fly by anyone with access.
  Recording causes endless loop errors but we want to use feedback functions 

  This function checks if the Hotjar configuration is set to record
*/
const isHotjarConfigRecording = async () => {
  try {
    const request = await fetch(
      `https://static.hotjar.com/c/hotjar-${HOTJAR_PROJECT_ID}.js?sv=6`
    )

    const text = await request.text()
    const settingsJson = text
      .split(' || ')[1]
      .split('!function()')[0]
      .replace(';', '')

    const json = JSON.parse(`[${settingsJson}]`)[0] as { record: boolean }
    return json.record
  } catch (error) {
    logger.error('Failed to read Hotjar config', { error, culprit: 'Hotjar' })
    return false
  }
}

const injectHotjar = async () => {
  if (!isProductionEnv()) {
    return
  }
  const hotjarShouldRecord = await isHotjarConfigRecording()
  // If Hotjar is configured to record, log an error
  if (hotjarShouldRecord) {
    logger.error('Hotjar is configured to record', { culprit: 'Hotjar' })
  } else {
    // If Hotjar is not configured to record, inject the script
    // !IMPORTANT! hash of this script is used in CSP header. If changed, update CSP header as well
    const script = document.createElement('script')
    script.innerHTML = `
    (function(h,o,t,j,a,r){
      h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
      h._hjSettings={hjid:${HOTJAR_PROJECT_ID},hjsv:6};
      a=o.getElementsByTagName('head')[0];
      r=o.createElement('script');r.async=1;
      r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
      a.appendChild(r);
  })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
`
    document.head.append(script)
    let pluginRegistered = false

    watch(
      () => getStateData().featureFlags['ENABLE_HOTJAR_PLUGIN'],
      (newFlag) => {
        if (newFlag === true && !pluginRegistered) {
          amplitude.addPlugin(HotjarPlugin)
          pluginRegistered = true
        } else if (pluginRegistered) {
          amplitude.removePlugin(HotjarPluginName)
          pluginRegistered = false
        }
      }
    )
  }
}

const fullstory = new Fullstory(
  FULLSTORY_ORGID,
  isProductionEnv(),
  isLocalEnv(),
  logger.error
)

export function startTracking() {
  if (state === 'started') {
    return
  }
  initLogger()
  initMonitor()
  initAnalytics()
  // TODO: initGTM()
  fullstory.init()
  injectHotjar()

  state = 'started'

  log('tracking is started')
}

export async function identifyUserInTracking(onLogin = false) {
  if (state === 'stopped') {
    return
  }
  const { id, email } = getStateProfile()

  await identifyUserInAnalytics(onLogin)
  identifyUserInLogger(id, email)
  identifyUserInMonitor(id, email)
  fullstory.identify(SharedDataType.ORCAS, id)

  log('user is identified')
}

export function anonymizeTracking() {
  if (state === 'stopped') {
    return
  }
  resetAnalyticsSession()
  fullstory.anonymize()

  log('user is anonymized')
}

export function stopTracking() {
  stopAnalytics()
  stopMonitor()
  stopLogger()
  fullstory.stop()

  state = 'stopped'

  log('tracking is stopped')
}

function log(message: string) {
  const prefix = '[orcas-shell - tracking]'
  logger.info(`${prefix} ${message}`, {})
  console.log(`${prefix} ${message}`)
}
