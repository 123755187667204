import { logger } from '@/services/logger/loggers'
import { clearState } from '@/store/setters'
import { cusAuthInstance } from '@/services/cusAuth'
import { anonymizeTracking } from '@/services/tracking'
import { anonymizeUserInLaunchDarkly } from '@/services/launchDarkly'

export type BaseLogoutOptions = {
  callBackend?: boolean
  calledByUser?: boolean
  redirectTo?: string
  replaceUrlTo?: string
}

export const logout = async (
  options: BaseLogoutOptions = { callBackend: false, calledByUser: false }
) => {
  await cusAuthInstance.logout()
  clearState()
  localStorage.removeItem('authenticationStatus')
  logger.info('[orcas-shell] Logout successful', options)
  logger.unsetUserData()
  let nextUrl = '/login'
  if (options.redirectTo) {
    nextUrl += `?redirectTo=${encodeURIComponent(options.redirectTo)}`
  } else if (options.replaceUrlTo) {
    // TODO: this is a temporary solution, should be removed after perform full redirect to the new URL
    nextUrl = options.replaceUrlTo
  }
  anonymizeTracking()
  await anonymizeUserInLaunchDarkly()

  // TODO: this is a temporary solution before mFs refactor tenants-related state management.
  window.location.replace(nextUrl)
  // router.push(nextUrl)
}
