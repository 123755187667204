import { OrcasStoreData, store } from '@/store'

export const getStateData = (): OrcasStoreData => {
  return store.state.data
}

export const getStateProfile = () => {
  if (!store.state.data.profile) {
    throw new Error('[Store] state.data.profile is not initialized')
  }
  return store.state.data.profile
}

export const getStateCompany = () => {
  if (!store.state.data.company) {
    throw new Error('[Store] state.data.company is not initialized')
  }
  return store.state.data.company
}

export const getStateProviders = () => {
  return store.state.providers
}

export const getStateCallbacks = () => {
  return store.state.callbacks
}

export const getStateFeatureFlags = () => {
  return store.state.data.featureFlags
}
