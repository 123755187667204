import { GTManager, GTMVuePlugin } from '@sennder/senn-node-google-tags'
import { GTM_CONTAINER_ID } from '@/common/config'
import { App } from 'vue'
import router from '@/router'
import { executeSafelyWithLogging } from '@/services/errors-handler'

let eventQueue: { event: string; eventData?: Record<string, any> }[] = []
let viewQueue: {
  screenName: string
  path: string
  additionalEventData?: Record<string, any>
}[] = []

class DefaultGTManager extends GTManager {
  init() {}
  pushEvent(event: string, eventData?: Record<string, any>) {
    eventQueue.push({ event, eventData })
  }
  trackView(
    screenName: string,
    path: string,
    additionalEventData?: Record<string, any>
  ) {
    viewQueue.push({ screenName, path, additionalEventData })
  }
}

export let gtManager = new DefaultGTManager({ gtmId: '' })

let initialized = false

export function initGTM() {
  if (initialized) {
    return
  }

  executeSafelyWithLogging(() => {
    gtManager = new GTManager({ gtmId: GTM_CONTAINER_ID })

    eventQueue.forEach((event) => {
      gtManager.pushEvent(event.event, event.eventData)
    })
    eventQueue = []

    viewQueue.forEach((view) => {
      gtManager.trackView(view.screenName, view.path, view.additionalEventData)
    })
    viewQueue = []
  }, 'gtManager.initGTM')

  initialized = true
}

export function registerGTMPlugin(app: App) {
  app.use(GTMVuePlugin, {
    gtManager,
    vueRouter: router,
  })
}
