import { logger } from '@/services/logger/loggers'
import { AUTH0_API_AUDIENCE } from '@/common/config'
import { logout } from '@/store/logoutActions'
import { gtManager } from '@/plugins/gtManager'
import {
  GetTokenOptions,
  Tenant,
} from '@sennder/senn-node-microfrontend-interfaces'

interface AccessToken {
  refresh_token: string
  access_token: string
  token_type: string
  expires_in: number
  expires_on: number
  id_token: string
}

interface TokenStore {
  token_request_key?: string
  tokens: { [audience: string]: AccessToken | undefined }
}

interface LogoutOptions {
  returnTo?: string
  client_id?: string
  federated?: boolean
  localOnly?: boolean
}

let TOKEN_STORE: TokenStore = { tokens: {} }
const TOKENS_LOCALSTORE_KEY = 'CUS_AUTH0_TOKENS'

/**
 * Parses the response from an HTTP request and returns the data as a generic type.
 * If the response is not successful, it throws an error with the specified error message.
 * Optionally, it can also handle custom error handling and logout functionality.
 *
 * @param response - The response object from the HTTP request.
 * @param errorMessage - The error message to be included in the thrown error.
 * @param shouldLogout - Indicates whether the user should be logged out on error. Default is true.
 * @param customErrorHandler - Optional custom error handler function.
 * @returns A promise that resolves to the parsed response data.
 * @throws An error with the specified error message if the response is not successful.
 */
const parseResponseData = async <T = Record<string, any>>(
  response: Response,
  errorMessage?: string,
  shouldLogout = true,
  customErrorHandler?: (reason: any) => Promise<never>
): Promise<T> => {
  if (response.ok) {
    return (await response.json()) as T
  } else {
    const reason = response.headers
      .get('content-type')
      ?.includes('application/json')
      ? await response.json()
      : await response.text()

    const redirectTo = window.location.pathname + window.location.search

    if (customErrorHandler) {
      try {
        await customErrorHandler(reason)
      } catch (error) {
        if (shouldLogout) await logout({ redirectTo })
        throw error
      }
    }

    const error = new Error(`${errorMessage} - ${JSON.stringify(reason)}`)
    Object.assign(error, { payload: reason })

    if (shouldLogout) await logout({ redirectTo })

    throw error
  }
}

const getCommonHeaders = () => {
  if (!process.env.VUE_APP_CARRIER_USER_SERVICE_URL) {
    throw new Error('VUE_APP_CARRIER_USER_SERVICE_URL is not found')
  }

  const tenantHeaderName =
    process.env.VUE_APP_CARRIER_USER_SERVICE_URL.includes('cloud.sennder.com')
      ? 'X-Tenant'
      : 'tenant'
  return {
    'Content-Type': 'application/json',
    [tenantHeaderName]: Tenant.SENNDER,
  }
}

const createCUSAuthInstance = () => {
  const persistTokens = () => {
    if (TOKEN_STORE.token_request_key) {
      localStorage.setItem(TOKENS_LOCALSTORE_KEY, JSON.stringify(TOKEN_STORE))
    } else {
      localStorage.removeItem(TOKENS_LOCALSTORE_KEY)
    }
  }

  const requestNewToken = async (audience: string): Promise<AccessToken> => {
    const response = await fetch(
      `${process.env.VUE_APP_CARRIER_USER_SERVICE_URL}/api/auth/token`,
      {
        method: 'POST',
        body: JSON.stringify({
          audience,
          credentials: TOKEN_STORE.token_request_key,
        }),
        headers: {
          ...getCommonHeaders(),
        },
      }
    )

    return parseResponseData(
      response,
      `Unable to fetch token for audience: ${audience}.`
    )
  }

  const requestNewTokenWithRefresh = async (
    tokenObject: AccessToken
  ): Promise<AccessToken> => {
    const response = await fetch(
      `${process.env.VUE_APP_CARRIER_USER_SERVICE_URL}/api/auth/token/refresh`,
      {
        method: 'POST',
        body: JSON.stringify({
          ...tokenObject,
        }),
        headers: {
          ...getCommonHeaders(),
        },
      }
    )
    return parseResponseData(
      response,
      'Unable to fetch new token.',
      true,
      async (reason: any) => {
        let notification = undefined
        const error = new Error(reason?.message || 'Unable to fetch new token')

        if (reason?.code === 'INVALID_REFRESH_TOKEN_ERROR') {
          logger.info(`Refresh token expired. Logging out..`, {})
        } else if (reason?.code === 'SCHEMA_VALIDATION_ERROR') {
          logger.error(
            `Schema validation error requesting new token with refresh. Logging out..`,
            { error }
          )
          notification = {
            category: 'warning',
            message: 'Please refresh the page and login again.',
          }
        } else {
          logger.error(
            `Unclassified error requesting new token with refresh. Logging out..`,
            { error }
          )
          notification = {
            category: 'warning',
            message: 'Something went wrong. Please login again.',
          }
        }

        if (notification) {
          Object.assign(error, { notification })
        }

        throw error
      }
    )
  }

  const revokeTokens = async () => {
    for (const [audience, tokenObject] of Object.entries(TOKEN_STORE.tokens)) {
      logger.info(`Revoking token for ${audience}...`, {})
      if (tokenObject && tokenObject.refresh_token) {
        await fetch(
          `${process.env.VUE_APP_CARRIER_USER_SERVICE_URL}/api/auth/logout`,
          {
            method: 'POST',
            body: JSON.stringify({
              refresh_tokens: [tokenObject.refresh_token],
              access_tokens: [tokenObject.access_token],
            }),
            headers: {
              ...getCommonHeaders(),
            },
          }
        )
      }
    }

    TOKEN_STORE = { tokens: {} }
    persistTokens()
  }

  const restoreSession = () => {
    const existingTokens = localStorage.getItem(TOKENS_LOCALSTORE_KEY)
    if (existingTokens) {
      const parsedExistingTokens = JSON.parse(existingTokens)
      if (parsedExistingTokens.token_request_key) {
        TOKEN_STORE = { ...parsedExistingTokens }
        if (!TOKEN_STORE.tokens) {
          TOKEN_STORE.tokens = {}
        }
      }
    }
  }

  restoreSession()

  return {
    async login(email: string, password: string) {
      if (TOKEN_STORE.token_request_key) {
        return
      }

      const response = await fetch(
        `${process.env.VUE_APP_CARRIER_USER_SERVICE_URL}/api/auth/login`,
        {
          method: 'POST',
          body: JSON.stringify({
            email,
            password,
          }),
          headers: {
            ...getCommonHeaders(),
          },
        }
      )

      const tokenRequestKey = await parseResponseData(
        response,
        'Unable to sign in.',
        false
      )

      if (tokenRequestKey.is_first_login) {
        try {
          logger.info(
            `Sending orcas_first_login to gtm for user_id: ${tokenRequestKey.user_id}`,
            {}
          )
          gtManager.pushEvent('orcas_first_login', {
            user_id: tokenRequestKey.user_id,
          })
        } catch (err: any) {
          logger.warn(
            `Failed to send gtm orcas_first_login event to gtm for user_id: ${tokenRequestKey.user_id}, error: ${err.stack}`,
            {}
          )
        }
      }

      TOKEN_STORE = {
        token_request_key: tokenRequestKey.key,
        tokens: {},
      }
      persistTokens()
    },
    async getToken(options?: GetTokenOptions) {
      if (!this.isAuthenticated()) {
        throw new Error('Unauthenticated')
      }

      if (!AUTH0_API_AUDIENCE) {
        throw new Error('AUTH0_API_AUDIENCE not found')
      }

      let audience = options?.audience
      if (!audience) {
        audience = AUTH0_API_AUDIENCE
      }

      let tokenObject = TOKEN_STORE.tokens[audience]

      if (!tokenObject) {
        tokenObject = await requestNewToken(audience)
      } else if (
        tokenObject.refresh_token &&
        tokenObject.expires_on - new Date().getTime() < 10000
      ) {
        tokenObject = await requestNewTokenWithRefresh(tokenObject)
      }

      TOKEN_STORE.tokens[audience] = tokenObject
      persistTokens()

      return tokenObject.access_token
    },
    async logout(options?: LogoutOptions): Promise<void> {
      await revokeTokens()
      if (options?.returnTo) {
        window.location.href = options.returnTo
      }
    },
    async isAuthenticated(): Promise<boolean> {
      return !!TOKEN_STORE.token_request_key
    },
  }
}

export const cusAuthInstance = createCUSAuthInstance()
