import { loadState } from '@/store'
import { getStateProfile } from '@/store/getters'
import { getAuth0Header } from '@/services/tokenManager'
import { identifyUserInTracking } from '@/services/tracking'
import { syncCookieConsent } from '@/services/cookies'
import { translationProvider } from '@/services/translationProvider'
import { checkUserTermsOrConsentAndRedirect } from '@/components/modal-manager'

export const loadUserData = async (onLogin = false) => {
  const authHeader = await getAuth0Header()
  if (!authHeader) {
    console.warn('[orcas-shell - userDataHelper] - Undefined auth header')
    return
  }

  if (!(await loadState())) {
    return
  }

  await Promise.all([
    translationProvider.set(getStateProfile().language),
    identifyUserInTracking(onLogin),
  ])

  // sync consent in background
  syncCookieConsent()

  checkUserTermsOrConsentAndRedirect()
}
